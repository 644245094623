.login-panel {
    /* background-color: white;
    min-height: 500px;
    min-width: 300px;
    width: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    margin-top: 20px;
    border: medium solid black;

  }

